import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import EditAppIcon from '@material-ui/icons/ExitToApp';
import { NavLink } from 'react-router-dom';
import { persistor } from '../store/load-store';
import firebase from 'firebase'
import { Logger } from '../util/logger';
import { signout } from '../store/actions-user';

const useStyles = makeStyles({
    list: {
        width: 250,
        flexDirection: 'column',
    },
    fullList: {
        width: 'auto',
    },
});

type Props = {
    open: boolean,
    onOpen: Function,
}
export default function MainDrawer(props: Props) {
    // @ts-ignore
    const classes = useStyles();

    const logoutPressed = () => {
        Logger.log('Logging out')
        signout();
    }

    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={() => props.onOpen()}
            onKeyDown={() => props.onOpen()}
        >
            <List>
                <NavLink to={'/users'} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key={'users'}>
                        <ListItemIcon><GroupIcon /></ListItemIcon>
                        <ListItemText primary={'Users'} />
                    </ListItem>
                </NavLink>
            </List>
            <Divider />
            <List>
                <ListItem onClick={() => logoutPressed()} button key={'logout'}>
                    <ListItemIcon><EditAppIcon /></ListItemIcon>
                    <ListItemText primary={'Logout'} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <Drawer
                open={props.open}
                onClose={() => props.onOpen()}
            >
                {sideList()}
            </Drawer>
        </div>
    );
}