import { createStore, Store, compose } from 'redux'
import { persistCombineReducers, persistStore, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { userReducers, UserState } from './user-state';
import { initUserStore } from './actions-user';


export type Action = {
    type: String,
    data?: any
}

export type RootState = {
    userStore: UserState,
}

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [
        'userStore',
    ],
    // Must be here otherwise Android will fail.
    keyPrefix: '',
}

// Only persists white list.
const rootReducers = persistCombineReducers(
    persistConfig, {
    userStore: userReducers,
}
)

let store: Store<RootState>

let persistor: Persistor

export const getStore = () => {
    return store
}

export const loadStore = (storeLoaded: (store: Store<RootState>) => void) => {
    // @ts-ignore
    store = createStore(
        rootReducers,
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() || compose
    )
    persistor = persistStore(store, undefined, () => {
        initStores(store)
        storeLoaded(store)
    })
}

export const initStores = (store: Store<RootState>) => {
    initUserStore(store)
}

export { persistor }