import { UserActions } from "./user-state"
import { Logger } from "../util/logger"
import { getStore, RootState, persistor } from "./load-store"
import { UserV1 } from "../models/user-v1"
import firebase, { functions, auth, firestore } from 'firebase'
import { CollectionNames } from "../util/config"
import { Store } from "redux"

export const initUserStore = (store: Store<RootState>) => {

}

export const signout = () => {
    firebase.auth().signOut()
        .then(() => {
            return persistor.purge()
        })
        .then(() => {
            window.location.href = '/'
        })
        .catch((e) => {
            Logger.error('Error logging out', e)
        })
}

export const getUser = (): UserV1 | undefined => {
    if (getStore().getState().userStore) {
        return getStore().getState().userStore.user
    }
}

export const fetchSelf = async (uid: string) => {
    Logger.log('Fetching self')
    const self = (await firestore().collection('users').doc(uid).get()).data() as UserV1;
    getStore().dispatch({ type: UserActions.gotUser, data: self })
}

export const addUser = async (email: string, role: string, chargingId?: string, connectorId?: string, idTag?: string, name?: string) => {
    const user: UserV1 = (await functions().httpsCallable('createUserV1')({ email, role, chargingId, connectorId, idTag, name })).data
    const users = getStore().getState().userStore.users;
    users[user.uid] = user;
    getStore().dispatch({ type: UserActions.gotAllUsers, data: users })
}

export const deleteUser = async (uid: string) => {
    await functions().httpsCallable('deleteUserV1')({ uid })
    const users = getStore().getState().userStore.users;
    delete users[uid];
    getStore().dispatch({ type: UserActions.gotAllUsers, data: users })
}

export const updateUser = async (uid: string, updateData: any) => {
    updateData.uid = uid;
    const user: UserV1 = (await functions().httpsCallable('updateUserV1')(updateData)).data
    const users = getStore().getState().userStore.users;
    users[user.uid] = user;
    getStore().dispatch({ type: UserActions.gotAllUsers, data: users })
}

export const passwordReset = async (uid: string) => {
    const users = getStore().getState().userStore.users;
    await auth().sendPasswordResetEmail(users[uid].email);
}

export const fetchAllUser = async () => {
    Logger.log('fetchAllUser')
    try {
        getStore().dispatch({ type: UserActions.isFetchingAllUsers, data: true })

        const userDocs = await firebase.firestore().collection(CollectionNames.users).get()

        const users: { [uid: string]: UserV1 } = {};
        userDocs.docs.forEach((d) => {
            const user = d.data() as UserV1;
            users[user.uid] = user;
        });
        Logger.log('Got all users: ' + Object.keys(users).length);
        getStore().dispatch({ type: UserActions.gotAllUsers, data: users })
    }
    catch (e) {
        Logger.log('Error fetching all users', e)
        getStore().dispatch({ type: UserActions.isFetchingAllUsers, data: false })
        throw e
    }
}

export const isLoggedIn = () => {
    if (getUser()) {
        return true
    }
    return false
}