import React from 'react';
import DefaultContainer from '../../components/default-container';
import UsersList from './users-list';
import { Padding } from '../../components/padding';

export const pageUsers = () => {
    return (
        <DefaultContainer ignoreMaxWidth={true}>
            <Padding padding={32}>
                <UsersList />
            </Padding>
        </DefaultContainer>
    )
}