import React from 'react'

type PaddingProps = {
    padding: number,
}
export class Padding extends React.Component<PaddingProps> {

    static defaultProps = {
        style: {},
    }

    render() {
        return (
            <div
                style={{ padding: this.props.padding }}
            >
                {this.props.children}
            </div>
        )
    }
}