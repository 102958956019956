const isDebuggingEnabled = (typeof atob !== 'undefined')

/**
 * Used for logging can be turned off for development mode.
 *
 * PS console.log is extremely slow in React.
 */
class LoggerService {

    /**
     * Custom logger for abstraction purposes.
     *
     * @param {string} text string of event.
     * @param {any} object anything.
     */
    log(text: string, object?: any) {
        if (isDebuggingEnabled) {
            if (object) {
                console.log(text, object)
            } else {
                console.log(text)
            }
        }
    }

    error(text: string, object?: any) {
        if (isDebuggingEnabled) {
            if (object) {
                console.error(text, object)
            } else {
                console.error(text)
            }
        }
    }
}

export const Logger = new LoggerService();