import React, { Component } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import firebase from 'firebase';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { loadStore, getStore, initStores } from './store/load-store';
import { Provider } from 'react-redux';
import { pageUsers } from './pages/users/page-users';
import { blue, lightGreen } from '@material-ui/core/colors';
import { fetchSelf, fetchAllUser } from './store/actions-user';
import { Logger } from './util/logger';
import { isLoggedIn } from './store/actions-user';


// Firebase setup.
const config = {
  apiKey: "AIzaSyAvdljtYEr36C6ogSi_n6l6UgJ7iP94oP0",
  authDomain: "movel-9a227.firebaseapp.com",
  databaseURL: "https://movel-9a227.firebaseio.com",
  projectId: "movel-9a227",
  storageBucket: "movel-9a227.appspot.com",
  messagingSenderId: "1065241425336",
  appId: "1:1065241425336:web:cc15ab368789bce4e60b4d",
  measurementId: "G-Q53CLRF43W"
};
firebase.initializeApp(config);
firebase.analytics();

const uiConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInSuccessUrl: '/users',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  credentialHelper: 'none',
};

// For creating the theme...
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: lightGreen,
  },
});

type State = {
  isLoggedIn: boolean
}
export default class App extends Component<any, State> {

  unregisterAuthObserver?: Function;

  constructor(props: any) {
    super(props)

    this.state = {
      isLoggedIn: false,
    }
  }

  componentDidMount() {
    loadStore(() => {

      this.setState({
        isLoggedIn: isLoggedIn()
      })

      // We're already logged in so do init requests.
      if (isLoggedIn()) {
        fetchSelf(getStore().getState().userStore.user?.uid ?? '').catch()
        fetchAllUser().catch()
      }

      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {

          // Do the initial fetch request if not logged in.
          if (user) {
            if (!isLoggedIn()) {
              fetchSelf(user.uid).then((v) => {
                return fetchAllUser()
              }).catch((e) => {
                Logger.log(e);
              })
            }
            this.setState({ isLoggedIn: true })
          } else {
            firebase.auth().signOut().catch(e => Logger.log(e));
            this.setState({ isLoggedIn: false })
          }
        }
      );
    })
  }

  componentWillUnmount() {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
    }
  }

  render() {
    return this.renderApp()
  }

  renderApp = () => {
    if (!this.state.isLoggedIn) {
      return (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )
    }

    return (
      <Provider store={getStore()}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div>
              <Switch>
                <Route exact path={'/users'} component={pageUsers} />
                <Route component={pageUsers} />
              </Switch>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    )
  }
}
