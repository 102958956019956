import React from 'react';
import MaterialTable, { Column } from 'material-table';
import { useSelector } from 'react-redux';
import { RootState, getStore } from '../../store/load-store';
import { UserV1 } from '../../models/user-v1';
import { addUser, deleteUser, updateUser, passwordReset } from '../../store/actions-user';
import { Logger } from '../../util/logger';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export default function UsersList() {

    const [dialogText, setDialogText] = React.useState('');

    const columns = [
        { title: 'Uid', field: 'uid', editable: false, hidden: true },
        { title: 'Email', field: 'email' },
        { title: 'Name', field: 'name', type: 'string' },
        { title: 'Charging ID', field: 'chargingId', type: 'string' },
        { title: 'ID Tag', field: 'idTag', type: 'string' },
        { title: 'Connector ID', field: 'connectorId', type: 'string' },
        { title: 'Role ', field: 'role', type: 'string' },
    ];

    const users = useSelector<RootState, Array<UserV1>>(state => {

        const allUsers = state.userStore.users;

        const usersArray: Array<UserV1> = Object.keys(allUsers).map((k) => {
            const user = allUsers[k]
            return user;
        })

        return usersArray;
    })

    const dialog = () => (
        <Dialog
            open={!!dialogText}
            onClose={() => setDialogText('')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"There was a problem"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogText('')} color="primary">
                    Ok
                    </Button>
            </DialogActions>
        </Dialog>
    )


    return (
        <div>
            {dialog()}
            <MaterialTable
                isLoading={getStore().getState().userStore.isFetchingAllUsers}
                title={'Users'}
                // @ts-ignore
                columns={columns}
                data={users}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete',
                        iconProps: {
                            color: 'error',
                        },
                        onClick: (event, rowData: any) => {
                            deleteUser(rowData.uid).then(() => { }).catch((e) => {
                                setDialogText('Cannot delete user, try again.');
                            });
                        }
                    },
                    {
                        icon: 'lock',
                        tooltip: 'Password Reset',
                        onClick: (event, rowData: any) => {
                            passwordReset(rowData.uid).then(() => { }).catch((e) => {
                                Logger.log(e);
                                setDialogText('Unable to password reset. Please try again.')
                            })
                        }
                    }
                ]}
                editable={{
                    onRowAdd: (newData) => new Promise((resolve, reject) => {
                        addUser(newData.email, newData.role, newData.chargingId, newData.connectorId, newData.idTag, newData.name)
                            .then(() => {
                                resolve()
                            })
                            .catch((e) => {
                                Logger.log(e);
                                if (e.code === 'already-exists') {
                                    setDialogText('Email address already exists');
                                    reject()
                                    return;
                                }
                                if (e.code === 'invalid-argument') {
                                    setDialogText('Incorrect role, can be either "admin" or "customer"');
                                    reject()
                                    return;
                                }
                                setDialogText('There was a problem adding the user. Error code: ' + e.code);
                                reject()
                            })
                    }),
                    onRowUpdate: (newData) => new Promise((resolve, reject) => {
                        updateUser(newData.uid, newData).then(() => {
                            resolve();
                        }).catch((e) => {
                            setDialogText('There was a problem updating the user.');
                            Logger.log(e);
                            reject()
                        })
                    }),
                }}
            />
        </div>
    );
}