import { UserV1 } from "../models/user-v1";

export const UserActions = {
    init: 'user-init',
    loggedIn: 'user-logged-in',
    loggedOut: 'user-logged-out',
    searchUsers: 'user-search-users',
    gotUser: 'got-user',
    gotAllUsers: 'got-all-users',
    isFetchingAllUsers: 'is-fetching-all-users',
}

export type UserState = {
    /**
     * The logged in user.
     */
    user?: UserV1,
    /**
     * List of users including customers and admins.
     */
    users: { [uid: string]: UserV1 },
    isFetchingAllUsers?: boolean,
}

const initialState = {
    user: undefined,
    users: {},
}

export const userReducers = (state: UserState = initialState, action: any): UserState => {
    switch (action.type) {
        case UserActions.init: {
            return {
                ...state,
                ...initialState,
            }
        }
        case UserActions.loggedIn: {
            return {
                ...state,
                user: action.data,
            }
        }
        case UserActions.gotUser: {
            return {
                ...state,
                user: action.data,
            }
        }
        case UserActions.isFetchingAllUsers: {
            return {
                ...state,
                isFetchingAllUsers: action.data,
            }
        }
        case UserActions.gotAllUsers: {
            return {
                ...state,
                isFetchingAllUsers: false,
                users: action.data,
            }
        }
        case UserActions.loggedOut: {
            return initialState
        }
        default: {
            return state
        }
    }
}