export const lottieConfigDefault = (animationData: any) => {
    return {
        loop: true,
        autoplay: true,
        animationData,
    }
}


export const CollectionNames = {
    users: 'users'
}