import React, { useState, useCallback } from 'react';

import '../App.css'
import MainAppBar from './main-app-bar';
import MainDrawer from './main-drawer';

type Props = {
    children: any,
    ignoreMaxWidth?: boolean,
}

export default function DefaultContainer(props: Props) {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleMenu = useCallback(() => setDrawerOpen(open => !open), []);

    return (
        <div style={{ flex: 1, }}>
            <MainAppBar onMenuPressed={toggleMenu} />
            <div style={{ maxWidth: props.ignoreMaxWidth ? undefined : 1400, width: '100%' }}>
                {props.children}
            </div>
            <MainDrawer open={drawerOpen} onOpen={toggleMenu} />
        </div>
    );
}
